export const REGISTARTION_URL = "/api/v2/registration/";
export const RESTORE_PASSWORD_URL = "/api/user/auth/reset-password";
export const RESET_PASSWORD_NEW_URL = "/api/user/auth/reset-password/new";
export const LOGIN_URL = "/api/v3/login";
export const VERIFICATION_URL = "/api/user/verification/email/verify";
export const RESEND_VERIFICATION_URL = "/api/user/verification/email/resend";
export const MY_PROFILE_URL = "/api/v2/profile/me/";
export const PROFILE_VIEW_URL = "/api/v2/profile/view/{uid}/";
export const LOCATION_COUNTRIES = "/api/v3/location/countries";
export const LOCATION_CITIES = "/api/v3/location/cities";
export const LOCATION_CITY = "/api/v3/location/city";
export const LOCATION_ACTIVE_COUNTRIES = "/api/v3/location/active-countries";
export const USER_VERIFICATION_EDIT_PROFILE = "/api/user/user/edit-profile";
export const MY_PROFILE_UPDATE_PROFILE = "/api/v2/profile/edit-profile/";
export const MY_PROFILE_UPDATE_PROFILE_BIO = "/api/v2/profile/edit-bio/";
export const USER_VERIFICATION_DONE_URL = "/api/v2/profile/verification-done/";
export const MY_PROFILE_PREFERENCES = "/api/v2/profile/preferences/";
export const MY_PROFILE_PREFERENCES_AGE = "/api/v2/profile/preferences-age/";
export const MY_PROFILE_EMAIL_SETTINGS = "/api/v2/profile/auth-email/";
export const MY_PROFILE_PASSWORD_SETTINGS = "/api/v2/profile/auth-password/";
export const MY_PROFILE_SOUND_SETTINGS = "/api/v2/profile/sound-settings/";
export const MY_PROFILE_PUSH_SETTINGS = "/api/v2/profile/settings/";
export const VERIFICATION_FORM_URL = "/docs/background_check_form_B2C.pdf";
export const SEARCH_SIMPLE_URL = "/api/v2/feed/search/";
export const SEARCH_EXTENDED_URL = "/api/v2/feed/search-extended/";
export const SEARCH_ALSO_URL = "/api/v2/feed/search-also/";
export const SEARCH_LIVE_STREAM_URL = "/api/v2/feed/search-live/";
export const FEED_SIMPLE_URL = "/api/v2/feed/search-simple/";
export const FEED_ONLINE_URL = "/api/v2/feed/online/";
export const FEED_LIVE_PROFILES = "/api/v2/feed/live/";
export const VIEW_EVENT = "/api/activity/view";
export const POST_REG_FUNNEL_URL = "/api/user/wizard";
export const POST_REG_FUNNEL_START = "/api/user/wizard/start";
export const POST_REG_FUNNEL_FINISH = "/api/user/wizard/finish";
export const POST_REG_FUNNEL_PREFERENCES = "/api/user/wizard/preferences";
export const POST_REG_FUNNEL_ABOUT = "/api/user/wizard/about";
export const USER_VIEW_URL = "/api/v2/user/{uid}/";
export const USER_REPORT_URL = "/api/v3/user/report";
export const USER_BLOCK_URL = "/api/v3/user/ban";
export const USER_ACTION_URL = "/api/user/action/{uid}";
export const LIKE_USER_PROFILE = "/api/activity/like";
export const WINK_USER_PROFILE = "/api/activity/wink";
export const SEND_QUICK_MESSAGE = "/api/v3/chat/free-msg";
export const SET_FAVOR_USER_PROFILE = "/api/activity/favorite-add";
export const REMOVE_FAVOR_USER_PROFILE = "/api/activity/favorite-delete";
export const USER_ACTION_ACTIVITY_PAGE_ALL = "/api/v3/activity/list";
export const USER_ACTION_ACTIVITY_MY_FAVORITE = "/api/v3/activity/my-favorites";
export const USER_ACTION_ACTIVITY_SET_STATUS =
  "/api/v2/action-activity-page/set-activity-status/";
export const PRIVATE_MEDIA_URL = "/api/v2/media/private-image/";
export const USER_MAIL_CREATE_SAVE = "/api/v2/mails/save/";
export const USER_MAIL_CREATE_DIALOG = "/api/v2/mails/create/";
export const USER_MAIL_GET_INBOX = "/api/v2/mails/list-inbox/";
export const USER_MAIL_GET_OUTBOX = "/api/v2/mails/list-outbox/";
export const USER_MAIL_GET_TRASH = "/api/v2/mails/list-trash/";
export const USER_MAIL_GET_TEMP = "/api/v2/mails/list-temp/";
export const USER_MAIL_VIEW_DIALOG = "/api/v2/mails/view/{uid}/";
export const USER_MAIL_LATEST_DIALOG = "/api/v2/mails/user-dialog/{uid}/";
export const USER_MAIL_VIEW_TRASH_DIALOG = "/api/v2/mails/view-trash/{uid}/";
export const USER_MAIL_MARK_READ = "/api/v2/mails/mark-read/";
export const USER_MAIL_MARK_UNREAD = "/api/v2/mails/mark-unread/";
export const USER_MAIL_DELETE = "/api/v2/mails/delete-mail/";
export const USER_MAIL_DIALOG_DELETE = "/api/v2/mails/delete-dialog/";
export const USER_MAIL_CREATE_SEND = "/api/v2/mails/send/";
export const USER_MAIL_RESTORE = "/api/v2/mails/restore/";
export const USER_MAIL_GET_DRAFT = "/api/v2/mails/get-draft/{uid}/";
export const USER_MEDIA = "/api/v3/user/media/{uid}";
export const DELETE_MEDIA_ITEM = "/api/v2/media/delete-media/";
export const CHANGE_MEDIA_PRIVATE = "/api/v2/media/change-media-private/";
export const ASK_NEW_MEDIA = "/api/v2/media/ask-media/";
export const CHAT_FILE_UPLOAD_URL = "/media/chat/upload-media/";
export const MAIL_FILE_UPLOAD_URL = "/media/internal-mail/upload-media/";
export const MAIL_FILE_DELETE_URL = "/media/internal-mail/delete-file/";
export const SEND_GIFT = "/api/v2/gifts/send/";
export const PROFILE_BILLING_HISTORY =
  "/payment/page-api/getUserBillingHistory/";
export const PROFILE_PHOTO_UPLOAD_URL = "/media/process-image/save-images/";
export const PROFILE_VIDEO_UPLOAD_URL = "/media/process-image/save-video/";
export const SEND_CONTACT_US_URL = "/api/v2/contact-us/";
export const USER_AUTOR_REPLY_VIEW = "/api/v3/user/view-reply";
export const USER_FIREBASE_SUBSCRIBE = "/api/user/user/fb-subscribe";
export const USER_COMPLETE_PROFILE = "/api/v2/profile/complete-profile/";
export const PAYMANT_PACKAGES = "/payment/page-api/getPaymentPageData/credits/";
export const PAYMANT_ONE_CLICK = "/payment/page-api/processOneClick";
export const PAYMENT_URL = "/payment/page-api/processPay";
export const PAYMANT_SUBSCRIPTION_LIST =
  "/payment/page-api/getUserSubscriptions";
export const PAYMANT_SUBSCRIPTION_STOP = "/payment/page-api/stopSubscription";
export const USER_DISABLE_PROFILE = "/api/v3/profile/disable";
export const USER_FIRE_BASE_DECLINE_SUBSCRIBE =
  "/api/v3/discount/web-push-ignore";
export const USER_DISCOUNTS_URL = "/api/discount/user-discounts";
export const DISCOUNT_REACTIVATION_URL = "/api/discount/reactivation";
export const DISCOUNT_AUTO_LOGIN_SUCCESS_SHOWED =
  "/api/v3/discount/auto-login-showed";
export const DISCOUNT_MAIL_CONFIRM_FIRST_TIME_SHOWED =
  "/api/v3/discount/mail-confirm-first";
export const DISCOUNT_MAIL_CONFIRM_SUCCESS_SHOWED =
  "/api/v3/discount/mail-confirm-success";
export const DISCOUNT_ONE_TIME_URL = "/api/v3/discount/one-time";
export const DISCOUNT_50_PERCENT_URL = "/api/v3/discount/half-price";
export const DISCOUNT_ONE_TIME_MAIL_URL = "/api/v3/discount/one-time-close";
export const DISCOUNT_FREE_CREDITS_URL = "/api/v3/discount/registration";
export const SHOWED_CHAT_CONFIRMATION_MODAL_URL =
  "/api/v2/profile/close-motivation-popup/";
export const PRESENTS_GET_URL = "/api/v2/gifts/";
export const PRESENTS_ORDERS_URL = "/api/v2/gifts/";
export const PRESENTS_GET_BY_IDS_URL = "/api/v2/gifts/info/";
export const PRESENTS_ORDER_URL =
  "/api/gifts/r/order?limit=20&page={pageNumber}";
export const CANCEL_PRESENTS_ORDER_URL = "/api/gifts/r/cancel/{orderUid}";
export const PRESENTS_HISTORY_GET_URL = "/api/v2/gifts/history/";
export const PRESENTS_HISTORY_ITEM_GET_URL =
  "/api/v2/gifts/history-items/{uid}/";
export const REQUEST_CONTACTS_STATUS_URL = "/api/v3/request-contacts/{uid}";
export const REQUEST_CONTACTS_MAKEE_REQUEST_URL =
  "/api/v3/request-contacts/{uid}";
export const VERIFICATION_FILES_DATA_GET_URL =
  "/api/user/verification/user-verification";
export const SAVE_VERIFICATION_FILES_URL = "/api/user/verification/files";
export const NEWS_FEEDS_GET_URL = "/api/v3/news";
export const CO_REG_FINISHED_URL = "/api/v2/discount/remarketing/";
export const NEWS_FEEDS_NEW_POSTS_CHECK_URL = "/api/v3/news/has-new/{uid}";
export const NEWS_FEEDS_NEW_POSTS_GET_URL = "/api/v3/news/newbies/{uid}";
export const NEWS_FEEDS_NEW_POSTS_CHECK_EXIST_URL = "/api/v3/news/has-new-feed";
export const BANNER_INFO_GET_URL = "/api/v3/banner?place=";
export const BANNERS_INFO_GET_URL = "/api/v3/banners?";
export const NEW_ACTIVITIES_CHECK_EXIST_URL = "/api/v3/activity/has-new";
export const BLOCKED_USERS_GET_URL = "/api/v3/user/prohibit/list";
export const UNBLOCK_USER_URL = "/api/v3/user/prohibit/unblock";
export const GOOGLE_SIGN_IN_URL = "/api/v3/user/google-sign-in";
export const RANDOM_MSG_SEND_URL = "/api/v3/wizard/random-msg";

export const NEWS_FEED_LIKE_ACT_URL = "/api/v3/user/like-news-post/create";
export const NEWS_FEED_DISLIKE_ACT_URL = "/api/v3/user/like-news-post/remove";
export const MY_PAYMENTS_DISPUTE_ACT_URL = "/api/v3/payment-dispute";
export const MY_PAYMENTS_DISPUTE_STATUS = "/api/v3/payment-dispute-status";
export const MY_REQUEST_CONTACTS = "/api/v3/requested-contacts";
export const COUNT_MAILS_IN_DIALOGS_URL =
  "/api/v3/mails/counter/mails-in-dialog";
export const VALIDATE_APPLE_PAY_SESSION_URL =
  "/payment/apple-pay/validate-session";
export const GIFTS_GET_URL = "/api/gifts/v/";
export const FINISH_NEW_DESIGN_SPLIT_URL = "/api/v3/user/finish-split-test";
export const MIGRATE_USER_TO_NEW_DESIGN_URL = "/api/user/app-version/migrate";
export const UNSUBSCRIBE_USER_URL = "/api/user/unsubscribe";
export const UNSUBSCRIBE_IS_ACTIVE_USER_URL = "/api/user/unsubscribe/is-active";
export const ACTIVATE_HALLOWEEN_DISCOUNT_API = "/api/discount/halloween";
export const VIEW_REAL_GIFT_URL = "/api/gifts/r/{userUid}/{giftUid}";
export const CHECKOUT_REAL_GIFT_URL = "/api/gifts/r/checkout/{uid}";

export const GET_PRESENTS_LIST_API = "/api/gifts/r/all/{userUid}";

export const GET_ONBOARDINGS = "/api/user/on-boarding";
export const GET_CONTEST_RESULT = "/api/contest/contest/result";

export const GET_CONTEST_PROFILES_LIST = "/api/contest/photo/list/{contestUid}";
export const GET_CONTEST_USER_DATA =
  "/api/contest/photo/{contestUid}/{profileUid}";
export const GET_CONTEST_DATA = "/api/contest/contest";
export const GET_CONTEST_PROFILES_LIST_MORE_INFO = "/api/user/user/info";
export const SET_CONTEST_VOTE = "/api/contest/vote/";
export const FEED_SIMILAR_PROFILES = "/api/user/feed/similar/";
export const GET_WIZARD_URL = "/api/user/wizard";
export const POST_WIZARD_START_URL = "/api/user/wizard/start";
export const POST_WIZARD_PREFERENCES_URL = "/api/user/wizard/preferences";
export const POST_WIZARD_ABOUT_URL = "/api/user/wizard/about";
export const POST_WIZARD_FINISH_URL = "/api/user/wizard/finish";
export const GET_WIZARD_PROFILES_URL = "/api/user/wizard/profiles";
export const GET_POPULAR_GIFTS_URL = "/api/gifts/v/popular";
export const POST_ADD_LIKE_MEDIA = "/api/activity/media/like/add";
export const POST_DELETE_LIKE_MEDIA = "/api/activity/media/like/delete/";
export const GET_LIKED_CONTENT = "/api/activity/media/like/list";
export const POST_FC_RESTORE_ID = "/api/user/user/fc-restore";
